.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.leftContainer {
  height: 100%;
  width: 55%;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/login-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rightContainer {
  height: 100%;
  width: 45%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.logo {
  height: 150px;
}

.errorMessage {
  color: "red";
}

.buttonContainer {
  margin-top: 20px;
}

.gradientButton {
  background: linear-gradient(to right, #6AC4C5, #387596);
  border: 0;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  color: #fff !important;
  text-transform: none !important;
  height: 48;
  padding: 0 30px;
  width: 150px;
}

.textInputContainer {
  margin-bottom: 12px;
}

.textInput {
  border: none;
  width: calc(100% - 20px);
  height: 40px;
  padding: 0 10px;
  border-radius: 3px;
  background: transparent;
}

.textInput:focus {
  outline: none !important;
  border: 2px solid #387596;
  box-shadow: 1px 2px 5px #719ECE;
}