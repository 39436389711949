.headerContainer {
    width: 100%;
    height: 100px;
    text-align: left;
    display: flex;
}

.logo {
    height: 80px;
}

.titleContainer {
    position: relative;
    flex: 1;
}

.title {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: "bold";
    font-size: 28px;
    padding-bottom: 10px;
}

.title:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 30%;
    background:#C6C6C6;
    height: 4px;
    border-radius: 10px;
}

@media (max-width: 767px) {
    .logo {
        padding: 10px 38px 10px 4em;
    }
    .titleContainer {
        display: none;
    }
}
 
@media (min-width: 768px) {
    .logo {
        padding: 10px 38px;
    }
}