.sidebarContainer {
    width: 200px;
    margin: 20px;
    left: 0;
    right: auto;
    top: 90px;
    bottom: 20px;
    display: flex;
    z-index: 1200;
    outline: none;
    position: absolute;
    overflow-y: auto;
    flex-direction: column;
    background-color: #FBFBFB !important;
}

.sidebarTrigger {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    color: #192b3c;
}

.sidebarItemContainer {
    padding: 20px;
}

.sidebarItem {
    list-style-type: none;
    text-align: left;
    height: 50px;
    border-bottom: solid 1px #e2e2e2;
    line-height: 50px;
    cursor: pointer;
}

.sidebarActiveItem {
    list-style-type: none;
    text-align: left;
    height: 50px;
    border-bottom: solid 1px #e2e2e2;
    line-height: 50px;
    font-weight: bold;
    color: #377395;
    cursor: pointer;
}

@media (max-width: 767px) {
    .sidebarTrigger {
       width: 4em;
    }
    .sidebarContainer {
        display: none;
    }
}
 
@media (min-width: 768px) {
    .sidebarTrigger {
        display: none
    }
}