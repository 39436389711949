.container {
    text-align: left;
}

.subtitle {
    font-weight: 400;
}

.denseTable {
    max-width: 60%;
    min-width: 500px;
    margin-bottom: 50px;
}

.tableHeader {
    color: #06688F !important;
    font-weight: bold !important;
    background-color: #f9f9f9 !important;
}

.media {
    height: 140px;
}

.textInputContainer {
    margin-bottom: 12px;
    width: 100%;
    border: #b8b8b8 solid 2px;
    border-radius: 5px;
}

.textInput {
    border: none;
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border-radius: 3px;
    background: transparent;
}
  
.textInput:focus {
    outline: none !important;
    border: 2px solid #387596;
    box-shadow: 1px 2px 5px #719ECE;
}