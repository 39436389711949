.root {
    width: 100vw;
    height: 100vh;
    overflow-x: 'hidden';
}

.content {
    min-height: 100%;
}

@media (max-width: 767px) {
    .sidebarTrigger {
       width: 4em;
    }
    .sidebarContainer {
        display: none;
    }
    .content {
        overflow-x: auto;
        margin-right: auto;
        margin-left: auto;
        margin-top: 50px;
        padding: 10px;
        margin: 10px;
    }
}
 
@media (min-width: 768px) {
    .sidebarTrigger {
        display: none
    }
    .content {
        width: calc(100% - 270px);
        padding: 20px;
        float: right;
    }
}